import React from "react"
import { Container, Row } from 'react-bootstrap'
import { graphql } from 'gatsby'

import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import ProductHero from '../components/ProductHero/ProductHero'
import SkewHeader from '../components/SkewHeader/SkewHeader'
import FeatureTable from '../components/FeatureTable/FeatureTable'
import ProductContactForm from '../components/ProductContactForm/ProductContactForm'
import {createScrollingRef} from '../utils/scrollHooks'
import {SectionLeft, SectionRight} from '../components/Section/Section'

const Canvas = props => {
  const [getCanvas, scrollToCanvas] = createScrollingRef("Canvas Page", "Get Canvas Scroll")

  return (
    <Layout>
      <SEO title="Canvas" />
      <ProductHero
        image={props.data.canvasHero.image.fluid}
        title="Canvas"
        paragraph1="Canvas is an OpenADR 2.0b Virtual Top Node built for testing VENs, piloting and running load shifting programs."
        paragraph2="Canvas Cloud is a SaaS solution for testing and pilots. Canvas Server is a licensed software, self-hosted VTN."
        cta="Get Canvas"
        ctaClick={scrollToCanvas}
        openadrBadge={props.data.openadrBadge.image.fixed}
      ></ProductHero>
      <Container>
        <div style={{ margin: "40px 0 160px 0" }}>
          <h2 className="section-header">How it works</h2>
          <div style={{ maxWidth: "400px", margin: "10px auto 40px auto" }}>
            <img src={props.data.canvasDiagram.image.file.url} alt="" />
          </div>
          <div style={{ maxWidth: "700px", margin: "auto" }}>
            <ul>
              <li>
                Canvas registers, monitors and controls devices with OpenADR
                Virtual End Nodes
              </li>
              <li>
                Canvas can be controlled through a browser based UI or
                programmatically through a custom API
              </li>
              <li>
                Canvas can run standalone or integrated with in an existing
                DERMS / DRMS system to handle all OpenADR communications
              </li>
            </ul>
          </div>
        </div>
        <hr style={{ margin: "100px 0" }} />
        <SectionLeft image={props.data.cloudImage.image.fluid}>
          <FeatureTable
            title="Canvas Cloud"
            subtitle="Canvas Cloud is the simplest solution for testing VENs and running
              small OpenADR pilots. We offer a 14 day trial for Canvas Cloud with no purchase required."
            featureList={[
              "Completely implements OpenADR 2.0b, can be used to test 2.0b and 2.0a VENs",
              "Full functionality for simple or complex VEN testing and pilot programs",
              "No IT overhead: runs entirely in the browser",
            ]}
          />
        </SectionLeft>
        <hr style={{ margin: "100px 0" }} />
        <SectionRight image={props.data.serverImage.image.fluid}>
          <FeatureTable
            title="Canvas Server"
            subtitle="Canvas Server is a licensed software solution that customers host
              themselves"
            featureList={[
              "Full, compliant implementation of OpenADR 2.0b",
              "Integrates with existing systems and managed through APIs",
              "High performance and scalable to thousands of VENs",
            ]}
          />
        </SectionRight>
      </Container>
      <div className="breaker-100" />
      <SkewHeader headerContent="Get Canvas" reference={getCanvas} />
      <div className="breaker-100" />
      <Container>
        <Row style={{ maxWidth: "700px", margin: "auto", minHeight: "500px" }}>
          <ProductContactForm
            formName="canvas-contact-form"
            formPrompt="Get in touch about Canvas"
            checkBoxes={[
              { label: "Canvas Cloud free trial", id: "canvasCloudTrial" },
              { label: "Canvas Cloud pricing", id: "canvasCloudPricing" },
              { label: "Canvas Server", id: "canvasServer" },
              { label: "Other", id: "other" },
            ]}
          />
        </Row>
      </Container>
    </Layout>
  )}

export default Canvas

export const canvasPageQuery = graphql`
         query {
           canvasHero: contentfulWebsiteImages(imageId: { eq: "canvasHero" }) {
             ...standardImage
           }
           openadrBadge: contentfulWebsiteImages(
             imageId: { eq: "openadr20bLogo" }
           ) {
             image {
               fixed(width: 200) {
                 ...GatsbyContentfulFixed_withWebp
               }
             }
           }

           cloudImage: contentfulWebsiteImages(imageId: { eq: "cloudImage" }) {
             ...standardImage
           }
           serverImage: contentfulWebsiteImages(
             imageId: { eq: "serverImage" }
           ) {
             ...standardImage
           }
           canvasDiagram: contentfulWebsiteImages(
             imageId: { eq: "canvasDiagramSVG" }
           ) {
             image {
               file {
                 url
               }
             }
           }
         }
       `