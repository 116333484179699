import React from 'react'
import {Row, Button} from 'react-bootstrap'
import {Link, useStaticQuery, graphql} from 'gatsby'
import Img from 'gatsby-image'

const FeatureTable = ({title, subtitle, featureList, cta, ctaLink}) => {
  const data = useStaticQuery(graphql`
    query {
      check: contentfulWebsiteImages(imageId: { eq: "greenCheckIcon" }) {
        image {
          fixed(height: 35) {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Row    >
      <div style={{ maxWidth: "600px", margin: "auto" }}>
        {title && <h2 style={{ textAlign: "left", paddingBottom: "10px", paddingLeft: "10px" }}>
          {title}
        </h2>}
        {subtitle && <p style={{ textAlign: "left", paddingBottom: "10px", fontSize: "0.9em", paddingLeft: "10px" }}>
          {subtitle}
          </p>}
        {featureList && featureList.length &&
        <table>
          <tbody>
             {featureList.map((feature, key)=>
                <tr key={key} style={{paddingBottom: "20px"}}>
                  <td style={{borderBottom: "0px", padding: "10px 0", width: "70px", textAlign: "center"}}>
                    <Img fixed={data.check.image.fixed} alt="" style={{marginBottom: "0px"}}/>
                  </td>
                  <td style={{padding: "10px 0"}}>
                    <p style={{margin: 0}}>
                      {feature}
                    </p>
                  </td>
                </tr>)}
            </tbody>
          </table>
        }
        {cta && ctaLink && 
          <div style={{ width: "100%", textAlign: "center" }}>
            <Link to={ctaLink} style={{ margin: "20px 0" }}>
              <Button size="lg" className="btn-gridfabric">
                {cta}
              </Button>
            </Link>
          </div>}
      </div>
    </Row>
  )
}

export default FeatureTable
